body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 600px) {
  .chessboard-container {
    width: 100%;
    max-width: 300px;
  }
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%; /* Ensure full height */
}

#root {
  min-height: 100vh; /* Make the root container take up full height */
}

.background-container {
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  background-attachment: fixed; /* Fixes the background to cover scrolling areas */
  min-height: 100vh; /* Ensures it extends the full height of the page */
}